import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ProductSlide extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="jumbotron jumbotron-fluid slide-product">
                <div className="container">
                    <section className="col-md-7">
                        <h1 className="display-4 text-center" style={{ color: this.props.colorText }}>{ this.props.name }</h1>
                        <img width="90%" src={ `${this.props.image}` } alt="gardenbox" />
                    </section>
                    <section className={ this.props.position == 'right' ? 'gardenbox-detail col-md-5': 'gardenbox-detail col-md-5' }>
                        <h3 className="text" style={{ color: this.props.colorText }}>{ this.props.size }</h3>
                        <br />
                        {/*<p className="lead text" style={{ color: this.props.colorText }}>COP {this.props.price.format(0, 3, ',') }</p>*/}
                        <p className="text" style={{ color: this.props.colorText }}>* {this.props.suggestion}</p>
                        <br />
                        <p className="text" style={{ color: this.props.colorText }}>{this.props.suggestion2}</p>
                        <br />
                        <div className="text-center">
                            <Link className="btn btn-warning btn-lg" role="button" to="/checkout/gardenbox">Comprar un Garden Box</Link>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default ProductSlide;