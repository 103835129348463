//constants file (data config)

export const BANK_NUMBER = '37900000753';

export const NIT = '901407347';

//PROD
export const WOMPY_KEY = 'pub_prod_OSBT07FgDvrKLTi88kFbF9VH6s1YYfvg';
export const WOMPY_REDIRECT = 'https://www.gardenboxcol.com';

export const DELIVERY_OPTIONS = {
  DELIVERY_LOCAL: "delivery_local",
  DELIVERY_NOT_LOCAL: "delivery_not_local",
  DELIVERY_PICKUP_STORE: "delivery_pickup_store",
};

//TEST
/*
export const WOMPY_KEY = 'pub_test_gfp5cTIkccrcx6h5WTDxdOGasKlMQwTG';
export const WOMPY_REDIRECT = 'http://localhost:8000';
*/

export const STEPS = [
    {
        key: '1',
        step: '1',
        icon: 'local_shipping',
        title: 'Selecciona tu Garden Box',
    },
    {
      key: '2',
      step: '2',
      icon: 'phone_in_talk',
      title: 'Productos Extras',
    },
    {
        key: '3',
        step: '2',
        icon: 'phone_in_talk',
        title: '¿A donde vamos?',
    },
    {
        key: '4',
        step: '3',
        icon: 'ac_unit',
        title: '¿Quién eres?',
    },
    {
        key: '5',
        step: '4',
        icon: 'ac_unit',
        title: '¿Cómo quieres pagar?',
    }
];

export const GARDEN_TYPES = [
    {
        key: '1',
        name: 'Garden Box Pequeña',
        size: 'Medidas 65cm x 46cm',
        price: 155000,
        pricegarden: 32000,
        safetymesh: 30000,
        personalization: 55000,
        service: 20000,
        astralpet: 10000,
        biozym: 20000,
        bags: 15000,
        personalizationtext: 'Máximo 15 Letras',
        suggestion: 'Recomendada para animales de máximo 12Kg. ',
        suggestion2: 'Perfecto para cachorros, razas pequeñas y medianas. Está elaborada con madera inmunizada, resiste intemperie y es reutilizable.',
        position: 'right',
        image: 'assets/gardenboxes/gb-small.png',
        colorText: '#000',
        allowOutsiteCity: true,
    },
    {
        key: '2',
        name: 'Garden Box Estandar (Mediano)',
        size: 'Medidas 85cm x 66cm',
        price: 220000,
        pricegarden: 53000,
        safetymesh: 35000,
        personalization: 55000,
        service: 20000,
        astralpet: 10000,
        biozym: 20000,
        bags: 15000,
        personalizationtext: 'Máximo 15 Letras',
        suggestion: 'Recomendada para animales de más de 12Kg.',
        suggestion2: 'Perfecto para razas medianas. Está elaborada con madera inmunizada, resiste intemperie y es reutilizable.',
        position: 'left',
        image: 'assets/gardenboxes/gb-medium.png',
        colorText: '#000',
        allowOutsiteCity: true,
    },
    {
        key: '3',
        name: 'GardenBox Grande',
        size: 'Medidas 125 x 66 cm',
        price: 320000,
        pricegarden: 80000,
        safetymesh: 40000,
        personalization: 55000,
        service: 25000,
        astralpet: 10000,
        biozym: 20000,
        bags: 15000,
        personalizationtext: 'Máximo 15 Letras',
        suggestion: 'Recomendada para animales de más de 18Kg.',
        suggestion2: 'Perfecto para razas Medianas, Grandes o 2 perros pequeños. Está elaborada con madera inmunizada, resiste intemperie y es reutilizable.',
        position: 'right',
        image: 'assets/gardenboxes/gb-big.png',
        colorText: '#000',
        allowOutsiteCity: false,
    },
    {
        key: '4',
        name: 'Garden Box Extra Grande',
        size: 'Medidas 125 x 86 cm',
        price: 415000,
        pricegarden: 100000,
        safetymesh: 48000,
        personalization: 55000,
        service: 25000,
        astralpet: 10000,
        biozym: 20000,
        bags: 15000,
        personalizationtext: 'Máximo 15 Letras',
        suggestion: 'Recomendada para animales de más de 27Kg.',
        suggestion2: 'Perfecto para razas grandes. Está elaborada con madera inmunizada, resiste intemperie y es reutilizable.',
        position: 'left',
        image: 'assets/gardenboxes/gb-extra.png',
        colorText: '#000',
        allowOutsiteCity: false,
    }
];

export const PAYMENTCOST_WOMPY = 10000;

export const OUTSIDE_MEDELLIN = 0;

export const AUTO_DELIVERY = 0;
