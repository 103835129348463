import React from "react";

const Step = (props) => (
  <section className="row">
    <div className="col-md-12 col-xs-12 step-single">
      <div className="step-circle">
        <p>{props.step}</p>
      </div>
      <p className="title-step"> {props.title} </p>
    </div>
  </section>
);

export default Step;
