import React, { Component } from 'react';
import FaqSimple from './faq-simple';

class Faq extends Component {

    constructor(props){
        super(props);
        this.state = {
            faqList: [
                {
                    key: '1',
                    question: '¿Debo limpiar la grama de la Garden Box?                    ',
                    answer: 'No. Lo único que debes hacer es recoger el popo como lo haces cuando sales al parque con tu peludo.'
                },
                {
                    key: '2',
                    question: '¿Me genera olor?',
                    answer: 'No, Los sustratos que usamos encapsulan los malos olores, evitando que salgan a la superficie. Cuando empiezas a sentir olor, es porque la grama está saturada y es hora de hacer el recambio.'
                },
                {
                    key: '3',
                    question: '¿Se filtra?',
                    answer: 'No. La grama tiene una bandeja plástica que evita que haya derrames. Esta bandeja tiene unas perforaciones de desagüe, para evitar que la grama se encharque en los casos donde la cantidad de agua ha sido más de la recomendada, o si está expuesta a la lluvia directa.'
                },
                {
                    key: '4',
                    question: ' ¿Qué mantenimiento requiere?                    ',
                    answer: 'Lo único que debes hacer es mantener húmeda la grama en época de verano o cuando reciba sol directo durante gran parte del día.'
                },
                {
                    key: '5',
                    question: '¿Qué hago si no quiere hacer pipí en la Garden Box?',
                    answer: 'Tenemos varios tips que te pueden ayudar. En estos casos te puedes comunicar al <strong>300 629 10 12</strong>. Adicional a esto, trabajamos de la mano con entrenadores que harán que tu peludo aprenda fácilmente. '
                },
                {
                    key: '6',
                    question: '¿Cada cuánto lo debo cambiar?                    ',
                    answer: 'La duración aproximada es 1 mes. Depende de la frecuencia con la que la use tu perro y de su tamaño. La Garden Box es un sanitario de emergencia, para usarlo en época de lluvias, en las madrugadas o cuando no puedes sacar a tu peludo. Si le das un uso más frecuente, es probable que dure menos tiempo.                    '
                },
                {
                    key: '7',
                    question: '¿Cómo se hace el recambio?',
                    answer: 'Hay 2 opciones. La primera, nosotros te hacemos llegar las gramas nuevas y tu realizas el cambio. Lo único que debes hacer es botar las gramas viejas y guardar las bandejas plásticas. Nosotros nos pondremos en contacto contigo para la devolución de las bandejas. Si tienes inconvenientes te podemos mostrar cómo se hace. <br /><br /> ¡En la segunda, nosotros nos encargamos de todo! ¡Tú y tu peludo solo tienen que disfrutar de su nueva grama! '
                },
                {
                    key: '8',
                    question: '¿Cuento se demora el envío de mi Garden Box?                    ',
                    answer: 'El tiempo de entrega depende de tu ubicación. Generalmente se demora de 1 a 5 días hábiles una vez hayamos recibido el pedido. Si tu Garden Box tiene requerimientos especiales (Ej: Personalización) el tiempo de entrega puede variar. '
                }
            ]
        }
    }

    render() {
        return (
            <div className="faq-section">
                <div className="container">
                    <h2>Preguntas frecuentes</h2>
                    <section className="faq-wrapper">
                        {
                            this.state.faqList.map( (item) =>
                                <FaqSimple { ...item } />
                            )
                        }
                    </section>
                </div>
            </div>
        );
    }
}

export default Faq;