import React, { Component } from 'react';
import ProductSlide from './components/product-slide';
import { GARDEN_TYPES } from '../../data/constants';
import ProductExtra from './components/product-extra';

class Product extends Component {
    render() {
        return (
            <div>
                {
                    GARDEN_TYPES.map((item, key) => <ProductSlide key={ key } { ...item } />  )
                }
                <ProductExtra />
            </div>
        );
    }
}

export default Product;