import React, { useEffect, useState } from "react";
import axios from "axios";

export const Coupon = ({ onChangeComponent }) => {
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [status, setStatus] = useState(0);
  const [processing, setProcessing] = useState(false);

  const handleChangeCode = (event) => {
    const newCode = event.target.value;
    setCode(newCode);
  };

  const onPressFindCode = (event) => {
    setProcessing(true);
    setTimeout(() => {
      callFindCodeRequest();
    }, 1000);
  };

  const callFindCodeRequest = () => {
    axios
      .get(`https://api.gardenboxcol.com/public/api/find-coupon`, {
        params: {
          code,
        },
      })
      .then((response) => {
        setStatus(response.data.status);
        if (response.data.status === 1) {
          setDiscount(response.data.coupon.percent_discount);
        } else {
          setDiscount(0);
        }
        setProcessing(false);
      })
      .catch(function(error) {
        console.log("catch", error);
        setProcessing(false);
      });
  };

  useEffect(() => {
    onChangeComponent(discount, code);
  }, [discount]);

  return (
    <div className="row box-coupon">
    <div className="col-md-6 col-md-offset-3 col-sm-12">
      <div className="border-form">
        <div className="coupon-wrapper">
          <section className="coupon-input">
            <div className="form-group">
              <input
                type="text"
                value={code}
                onChange={handleChangeCode}
                name="code"
                className="form-control"
                id="code"
                placeholder="Ingresa tu cupón de descuento"
              />
            </div>
          </section>
          <section className="coupon-button">
            <button
              onClick={onPressFindCode}
              disabled={processing}
              className="btn full btn-info btn-md"
            >
              {processing ? "Búscando código" : "Aplicar Código"}
            </button>
          </section>
        </div>
        {status === 1 && (
          <div>
            <p className="text-success">Cupón con descuento de {discount}%</p>
          </div>
        )}
        {status === 2 && (
          <div>
            <p className="text-danger">Código no válido</p>
          </div>
        )}
      </div>
      </div>
    </div>
  );
};
