import React, { useEffect, useState } from "react";

const QUANTITY_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const EXTRA_PRODUCT_KEY = {
  BAGS: "hasBags",
  CUSTOMIZED: "hasCustomized",
  SAFETY_MESH: "hasSafetyMesh",
  ASTRAL_PET: "hasAstralpet",
  BIOZYM: "hasBiozym",
};

const DEFAULT_CHECKED = {
  hasCustomized: false,
  hasSafetyMesh: false,
  hasAstralpet: false,
  hasBiozym: false,
  hasBags: false,
};

export const ExtraProductsSelection = ({
  gardenTypes = [],
  gardenBoxTypeSelected,
  maxQuantity = 0,
  onChangeComponent,
}) => {
  const [quantityCustomization, setQuantityCustomization] = useState(0);
  const [quantitySafeMesh, setQuantitySafeMesh] = useState(0);
  const [quantityAstralPet, setQuantityAstralPet] = useState(0);
  const [quantityBioZym, setQuantityBioZym] = useState(0);
  const [quantityBags, setQuantityBags] = useState(0);
  const [gardenData, setGardenData] = useState(null);
  const [total, setTotal] = useState(0);
  const [hasChecked, setHasChecked] = useState(DEFAULT_CHECKED);

  useEffect(() => {
    const gardenDefault = gardenTypes[0];
    setGardenData(gardenDefault);
  }, []);

  useEffect(() => {
    const garden = gardenTypes.find(
      (item) => item.name === gardenBoxTypeSelected
    );
    setGardenData(garden);
  }, [gardenBoxTypeSelected]);

  useEffect(() => {
    if (gardenData) {
      calculateTotalExtraProducts();
    }
  }, [
    gardenData,
    quantityCustomization,
    quantitySafeMesh,
    quantityAstralPet,
    quantityBioZym,
    quantityBags,
  ]);

  useEffect(() => {
    setQuantityCustomization(hasChecked.hasCustomized ? maxQuantity : 0);
    setQuantitySafeMesh(hasChecked.hasSafetyMesh ? maxQuantity : 0);
  }, [maxQuantity, hasChecked]);

  useEffect(() => {
    emitEventComponent();
  }, [total]);

  const calculateTotalExtraProducts = () => {
    const totalExtraProducts =
      quantityCustomization * gardenData.personalization +
      quantitySafeMesh * gardenData.safetymesh +
      quantityAstralPet * gardenData.astralpet +
      quantityBioZym * gardenData.biozym +
      quantityBags * gardenData.bags;
    setTotal(totalExtraProducts);
  };

  const emitEventComponent = () => {
    const priceExtraProducts = {
      unitPriceSafeMesh: gardenData ? gardenData.safetymesh : 0,
      unitPriceCustomization: gardenData ? gardenData.personalization : 0,
      unitPriceSafeBags: gardenData ? gardenData.bags : 0,
      unitPriceSafeBioZym: gardenData ? gardenData.biozym : 0,
      unitPriceAstralPet: gardenData ? gardenData.astralpet : 0,
    };

    const quantityExtraProducts = {
      quantityCustomization,
      quantitySafeMesh,
      quantityAstralPet,
      quantityBioZym,
      quantityBags,
    };

    onChangeComponent(quantityExtraProducts, priceExtraProducts, total);
  };

  const onChangeQuantityCustomization = (event) => {
    const quantity = event.target.value;
    setQuantityCustomization(quantity);
  };

  const onChangeQuantityAstralPet = (event) => {
    const quantity = event.target.value;
    setQuantityAstralPet(quantity);
  };

  const onChangeQuantityBioZym = (event) => {
    const quantity = event.target.value;
    setQuantityBioZym(quantity);
  };

  const onChangeQuantityBags = (event) => {
    const quantity = event.target.value;
    setQuantityBags(quantity);
  };

  const onChangeQuantitySafeMesh = (event) => {
    const quantity = event.target.value;
    setQuantitySafeMesh(quantity);
  };

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const newChecked = { ...hasChecked };
    newChecked[name] = value;
    setHasChecked(newChecked);
    switch (name) {
      case EXTRA_PRODUCT_KEY.ASTRAL_PET:
        setQuantityAstralPet(value ? 1 : 0);
        break;
      case EXTRA_PRODUCT_KEY.BAGS:
        setQuantityBags(value ? 1 : 0);
        break;
      case EXTRA_PRODUCT_KEY.BIOZYM:
        setQuantityBioZym(value ? 1 : 0);
        break;
      case EXTRA_PRODUCT_KEY.CUSTOMIZED:
        setQuantityCustomization(0);
        break;
      case EXTRA_PRODUCT_KEY.SAFETY_MESH:
        setQuantitySafeMesh(0);
        break;
    }
  };

  const makeQuatityComponent = ({
    array = QUANTITY_ARRAY,
    text,
    smallText,
    value,
    handler,
    checked = false,
    nameCheckbox = "",
    showSelectQuantity = false,
  }) => {
    return (
      <section>
        <div className="form-check">
          <input
            id={nameCheckbox}
            name={nameCheckbox}
            checked={checked}
            onChange={handleCheckboxChange}
            type="checkbox"
            className="form-check-input"
          />
          <label htmlFor={nameCheckbox}>
            {" "}
            {text} <small>({smallText})</small>
          </label>
        </div>
        {checked && showSelectQuantity && (
          <div className="wrapper-select">
            <label>Cantidad</label>
            <select
              value={value}
              onChange={handler}
              className="form-control-select"
            >
              {array.map((item) => (
                <option key={item} value={item}>
                  {" "}
                  {item}{" "}
                </option>
              ))}
            </select>
          </div>
        )}
      </section>
    );
  };

  return (
    <div className="col-md-6 col-md-offset-3 col-sm-12 form-containter">
      <div className="form-group">
        {makeQuatityComponent({
          array: [...Array(parseInt(maxQuantity) + 1).keys()],
          text: "Incluir Personalización",
          smallText: "Máximo 15 Letras",
          value: quantityCustomization,
          handler: onChangeQuantityCustomization,
          checked: hasChecked.hasCustomized,
          nameCheckbox: EXTRA_PRODUCT_KEY.CUSTOMIZED,
        })}
        <br />
        {makeQuatityComponent({
          array: [...Array(parseInt(maxQuantity) + 1).keys()],
          text: "Incluir Malla de seguridad",
          smallText:
            "La malla de seguridad la recomendamos para aquellos cachorros o perros inquietos que les encanta jugar con la grama, de esta manera protegemos la grama para que cuando llegues a casa no encuentres la grama por todo tu hogar",
          value: quantitySafeMesh,
          handler: onChangeQuantitySafeMesh,
          checked: hasChecked.hasSafetyMesh,
          nameCheckbox: EXTRA_PRODUCT_KEY.SAFETY_MESH,
        })}
        <br />
        {makeQuatityComponent({
          text: "Incluir Mon Ami Pet",
          smallText:
            "Ideal para limpiar pisos. Sus bioenzimas eliminan los malos olores especialmente los de materia orgánica, orina, materia fecal, vómito, etc.",
          value: quantityAstralPet,
          handler: onChangeQuantityAstralPet,
          checked: hasChecked.hasAstralpet,
          nameCheckbox: EXTRA_PRODUCT_KEY.ASTRAL_PET,
          showSelectQuantity: true,
        })}
        <br />
        {makeQuatityComponent({
          text: "Incluir Bio Zym",
          smallText:
            "El Bio Zym es un limpiador y desodorizante biodegradable a base de enzimas naturales que facilita que las bacterias presentes en el garden box, pisos o telas consuman cualquier material de desecho orgánico y al mismo tiempo elimina los olores.",
          value: quantityBioZym,
          handler: onChangeQuantityBioZym,
          checked: hasChecked.hasBiozym,
          nameCheckbox: EXTRA_PRODUCT_KEY.BIOZYM,
          showSelectQuantity: true,
        })}
        <br />
        {makeQuatityComponent({
          text: "Incluir Bolsas Solubles",
          smallText:
            "Bolsas Invisiblebag solubles en agua en minutos, biodegradables, compostables, no tóxicas, ambientalmente seguras y no deja residuos en la tierra. 0% plastico 100% ecoamigables.",
          value: quantityBags,
          handler: onChangeQuantityBags,
          checked: hasChecked.hasBags,
          nameCheckbox: EXTRA_PRODUCT_KEY.BAGS,
          showSelectQuantity: true,
        })}
      </div>
      <h3 className="text-right">
        <small>Total productos extras</small> COP {total.format(0, 3, ",")}{" "}
      </h3>
    </div>
  );
};
