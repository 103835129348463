import React from 'react';
import './App.css';
import WhatsappChat from './components/Common/whatsapp-chat';

export const App = (props) => {
  return (
    <div>
      <WhatsappChat />
      {props.children}
    </div>
  );
};
