import React, { Component } from 'react';
import axios from 'axios';

class Auth extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    }

    submitHandler(event){
        event.preventDefault();
        this.login();
    }

    login(){
        let data = this.state;
        axios.post(`https://api.gardenboxcol.com/public/api/login`, data)
        .then( async (response) => {
            //console.log(response);
            await localStorage.setItem('token', response.data.token);
            setTimeout( () => {
                this.props.history.push('/auth/admin');
            }, 400)
        })
        .catch(function (error) {
            console.log(error);
            alert('Usuario o contraseña incorrecta');
        });
    }

    async handleChangeEmail(event){
        await this.setState({email: event.target.value});
    }

    async handleChangePassword(event){
        await this.setState({password: event.target.value});
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                        <div className="login-panel">
                            <form onSubmit={this.submitHandler.bind(this)}>
                                <div className="form-group">
                                    <label>Correo electrónico</label>
                                    <input value={this.state.email} onChange={this.handleChangeEmail.bind(this)} type="email" className="form-control" id="exampleInputEmail1" placeholder="Correo" />
                                </div>
                                <div className="form-group">
                                    <label>Contraseña</label>
                                    <input value={this.state.password} onChange={this.handleChangePassword.bind(this)} type="password" className="form-control" id="exampleInputPassword1" placeholder="Contraseña" />
                                </div>
                                <button type="submit" className="btn btn-default">Ingresar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Auth;
