import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <section className="container header-container">
                <div className="col-md-9">
                    <Link to="/">
                        <img width="210px" src="/assets/logo.png" />
                    </Link>                    
                </div>
                <div className="col-md-3">
                    <div className="container-contact">
                        <h3>Contáctanos</h3>
                        <h4>Whatstapp</h4>
                        <p className="follow-title">
                            <i className="fab fa-whatsapp"></i> 
                            <a target="_blank" href="https://wa.me/573006291012"> +57 3006291012</a>
                        </p>
                        <p className="follow-title">
                            <i className="fab fa-instagram"></i> <a target="_blank" href="https://instagram.com/gardenboxcol">gardenboxcol</a>
                        </p>
                        {/*<div className="social-icons">
                            <ul>
                                <li><a target="_blank" href="https://www.facebook.com/gardenboxcol"><i className="fab fa-facebook"></i></a></li>
                                <li><a target="_blank" href="/"><i className="fab fa-twitter"></i></a></li>
                                <li><a target="_blank" href="https://instagram.com/gardenboxcol"><i className="fab fa-instagram"></i></a></li>
                                <li><a target="_blank" href="/"><i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>*/}
                    </div>
                </div>
            </section>
        );
    }
}

export default Header;