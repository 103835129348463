import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Main from './components/main';
import Home from './components/Home/home';
import Checkout from './components/Checkout/checkout';
import ScrollToTop from './components/Common/scroll-to-top';

import Product from './components/Product/product';
import OrderEnded from './components/OrderEnded/order-ended';
import Admin from './components/Admin/admin';
import Auth from './components/Auth/auth';
import HowWorks from './components/HowWorks/howworks';
import { App } from './App';

export const Routers = () => {
  return (
    <Router>
      <Main>
        <ScrollToTop>
          <App>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/home' component={Home} />
              <Route path='/como-funciona' component={HowWorks} />
              <Route path='/checkout/:type' component={Checkout} />
              <Route path='/product' component={Product} />
              <Route path='/order-received' component={OrderEnded} />
              <Route path='/payment' component={OrderEnded} />
              <Route path='/auth/admin' component={Admin} />
              <Route path='/login' component={Auth} />
            </Switch>
          </App>
        </ScrollToTop>
      </Main>
    </Router>
  )
}
