import React from 'react';

const AboutGarden = () => {
    return (
        <div className="section-about">
            <div className="container">
                <div className="col-md-4">
                    <p>
                        La Garden Box es un Jardín sanitario para mascotas con grama 100% natural. Nuestras cajas de madera inmunizada contienen unas bandejas plásticas (retornables) donde se encuentra la grama sembrada sobre un sustrato que absorbe olores.
                        La duración de la Garden box es de 25 a 30 días aproximadamente, que varía dependiendo del tamaño de tu mascota y la frecuencia de uso.
                    </p>
                </div>
                
                <div className="col-md-offset-1 col-md-7">
                    <h3>La Garden Box es ideal para ti si:</h3>
                    <ul>
                        <li>Vives en un apartamento sin zonas verdes</li>
                        <li>Trabajas durante horas y te queda poco tiempo para sacar a tu peludo</li>
                        <li>Desea evitar que tu perro haga sus necesidades en lugares inadecuados </li>
                        <li>Tu peludo tiene movilidad limitada </li>
                        <li>Tienes un perro mayor o con alguna lesión</li>
                        <li>Desea evitar sacar a tu perro tarde en la noche o bajo la lluvia</li>
                        <li>No quieres volver a limpiar césped artificial ni ver los pads desechables por toda la casa.</li>
                    </ul>
                    <br />
                    <h4>
                        Manejamos 3 tipos de grama de acuerdo a la temporada: Zoysia Japonesa, zoysia Toro, San Agustín enano
                    </h4>
                    <p>* Sustratos orgánicos a base de fibras de coco</p>
                </div>
            </div>
        </div>
    );
};

export default AboutGarden;