import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MainSlide extends Component {

    constructor(props){
        super(props);
        this.state = {
            backImage: 'assets/background-tapiz-1.jpg',
            image: 'assets/garden-slide-1.png'
        };
    }

    render() {
        return (
            <div className="jumbotron jumbotron-fluid slide-app" style={{ backgroundColor: `#ededed`}}>
                <div className="container">
                    <section className="col-md-6">
                        <br /><br />
                        <img width="100%" src={`${this.state.image}`} alt="gardenbox" />
                    </section>
                    <section className="col-md-6">
                        <h1 className="display-4 text-center">¿Qué es Garden Box?</h1>
                        <h3 className="text">La mejor grama 100% real, recomendada por veterinarios, etólogos y entrenadores.</h3>
                        <br />
                        <p>Garden box es la solución natural para deshacerse de los pañales desechables que contaminan el medioambiente, las gramas artificiales difíciles de limpiar y los altos costos en detergentes!</p>
                        <p className="lead text">No es un sustituto para pasear a tu perro.  ¡Definitivamente es un sustituto para dejar de limpiar los desastres de tu peludo por todo tu hogar!</p>
                        <div className="text-center buttons-main-slide">
                            <div>
                                <p>Soy nuevo</p>
                                <Link className="btn btn-warning btn-lg" role="button" to="/product">Ver Tipos de GardenBox</Link>
                            </div>
                            <div>
                                <p>Ya soy cliente</p>
                                <a target="_blank" href="https://wa.me/573006291012" className="btn btn-warning btn-lg" role="button" to="/product">Solicita un Repuesto</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default MainSlide;