import React, { Component } from "react";
import {
  PAYMENTCOST_WOMPY,
  BANK_NUMBER,
  NIT,
  DELIVERY_OPTIONS,
} from "../../../data/constants";

class ActionBuy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "consignment",
      paymentCost: 0,
      consignmentNumber: "",
      shippingMethod: this.props.shippingMethod,
    };
  }

  /*static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.shippingMethod !== prevState.shippingMethod) {
      return {
        selectedOption: "consignment",
        paymentCost: 0,
        consignmentNumber: "",
        shippingMethod: prevState.shippingMethod,
      };
    }
    return null;
  }*/

  componentDidMount() {
    this.emitEventComponent();
  }

  async handleChangeConsignmentNumber(event) {
    await this.setState({ consignmentNumber: event.target.value });
    this.emitEventComponent();
  }

  async handleOptionChange(changeEvent) {
    let option = changeEvent.target.value;
    let paymentCost = 0;
    if (option == "wompy") {
      paymentCost = PAYMENTCOST_WOMPY;
    }
    await this.setState({
      selectedOption: option,
      paymentCost: paymentCost,
    });
    this.emitEventComponent();
  }

  emitEventComponent() {
    this.props.onChangeComponent(
      this.state.paymentCost,
      this.state.selectedOption,
      this.state.consignmentNumber
    );
  }

  render() {
    let buttonText =
      this.state.selectedOption === "epayco"
        ? "Paga en modo seguro"
        : "Ordena acá";
    buttonText = !this.props.processing
      ? buttonText
      : "Procesando su orden ...";
    return (
      <div className="col-md-6 col-md-offset-3 col-sm-12 buy-containter">
        <div className="radio custom-radio-input">
          <label>
            <input
              type="radio"
              value="wompy"
              name="paymentRadio"
              onChange={this.handleOptionChange.bind(this)}
              checked={this.state.selectedOption === "wompy"}
            />
            Pago seguro con Tarjeta Débido y Crédito (<strong>+</strong> COP
            10,000)
          </label>
        </div>
        <div className="radio custom-radio-input">
          <label>
            <input
              type="radio"
              value="consignment"
              name="paymentRadio"
              onChange={this.handleOptionChange.bind(this)}
              checked={this.state.selectedOption === "consignment"}
            />
            Pago por consignación o transferencia Bancolombia (app)
          </label>
        </div>
        <div className="radio custom-radio-input">
          <label>
            <input
              disabled={
                this.props.shippingMethod ===
                DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL
              }
              type="radio"
              value="upon_delivery"
              name="paymentRadio"
              onChange={this.handleOptionChange.bind(this)}
              checked={
                this.props.shippingMethod !==
                  DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL &&
                this.state.selectedOption === "upon_delivery"
              }
            />
            {this.props.shippingMethod ===
            DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL ? (
              <span>
                <del>Pago contra entrega</del>{" "}
                <strong>
                  {" "}
                  Opción NO Disponible para envíos a otras ciudades por fuera
                  del área metropolitana de Medellín
                </strong>
              </span>
            ) : (
              <span>Pago contra entrega</span>
            )}
          </label>
        </div>

        <br />

        {this.state.selectedOption === "consignment" ? (
          <div className="form-group">
            <p>
              Realiza una consignación por el valor de la orden a la cuenta{" "}
              <span className="highligh-text">
                #{BANK_NUMBER} de Ahorros Bancolombia{" "}
              </span>
              a nombre de Garden Box. <br />
              <strong>Nit:</strong> {NIT}
            </p>
            <br />
            <div className="center-image">
              <p>Escanea este código QR en tu app Bancolombia</p>
              <img src="/assets/qr.jpg" width="280" />
            </div>
            <br />
            <p className="highligh-text">
              Al momento de realizar la consignación envíar el comprobante al{" "}
              <a target="_blank" href="https://wa.me/573006291012">
                Whastsapp 3006291012
              </a>
            </p>
            <br />
          </div>
        ) : null}

        <div className="text-center">
          <h2>
            <small>Total</small> COP{" "}
            {this.props.totalCheckout.format(0, 3, ",")}
          </h2>
          <button
            className={
              this.props.processing
                ? "btn btn-warning btn-lg disabled"
                : "btn btn-warning btn-lg"
            }
            type="submit"
          >
            {" "}
            {buttonText}{" "}
          </button>
        </div>
        <br />
        <p className="highligh2-text">
          ¿Tienes otras indicaciones de tu orden? ¡No te preocupes! Nos
          pondremos en contacto contigo para coordinar la hora de entrega de tu
          compra entre otros detalles adicionales
        </p>
      </div>
    );
  }
}

export default ActionBuy;
