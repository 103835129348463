import React, { Component } from "react";
import axios from "axios";

class HowWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  async componentDidMount() {
    this.callProductsRequest();
  }

  async callProductsRequest() {
    try {
      axios
        .get(`https://api.gardenboxcol.com/public/api/products`)
        .then((response) => {
          this.setState({ products: response.data.products });
        })
        .catch(function(error) {
          console.log("catch", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  render() {
    if (this.state.products.length > 0) {
      return (
        <div className="container section-howworks">
          <section className="row">
            <div className="col-md-7">
              <h2>¿Cómo funciona?</h2>
              <p>
                Nuestras cajas de madera inmunizada contienen unas bandejas
                plásticas (retornables) donde se encuentra la grama sembrada
                sobre un sustrato que absorbe olores.
              </p>
              <p>
                La duración de la grama de la Garden box es de 20 a 40 días
                aproximadamente, que varía dependiendo del tamaño de tu mascota
                y la frecuencia de uso.
              </p>
              <p>
                Cuando la grama y el sustrato están saturados es necesario
                cambiarla y es momento de solicitar tu repuesto.
              </p>
            </div>
          </section>
          <section className="row">
            <div className="col-md-7">
              <h2>REPUESTOS</h2>
              <p>
                <strong>
                  Los repuestos de Grama varían dependiendo del tamaño de Garden
                  Box que tengas:
                </strong>
              </p>
              <ul>
                {this.state.products.map((item) => (
                  <li>
                    Repuesto {item.name} ${item.pricegarden.format(0, 3, ',')}
                  </li>
                ))}
                {/*<li>
                  La bandeja de grama nueva es entregada en portería o donde se
                  acuerde con el cliente.
                </li>
                <li>Bandeja usada sin devolución $5.000</li>
                */}
              </ul>
            </div>
            <div className="col-md-5">
              <br />
              <br />
              <img width="90%" src="assets/repuestos.jpg" />
            </div>
          </section>
          <section className="row">
            <div className="col-md-7">
              <h2>SERVICIO CLEAN UP</h2>
              <p>
                Con nuestro servicio de limpieza - CleanUP queremos reemplazar
                el tiempo que gastas diariamente limpiando con más juegos y más
                paseos. ¡Nosotros nos ocupamos de limpiar la Garden Box de tu
                peludo por ti!
              </p>
              <p>Nuestro servicio consiste en:</p>
              <ul>
                <li>Disposición final del Pasto usado</li>
                <li>Limpieza de la Caja y el Área donde se encuentra</li>
                <li>Desinfección del espacio</li>
                <li>Instalación del Pasto Nuevo</li>
              </ul>
              <h4>Servicio de Recambio para Garden</h4>
              <ul>
                {this.state.products.map((item) => (
                  <li>
                    {item.name} ${item.clean_up.format(0, 3, ',')}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-5">
              <br />
              <br />
              <br />
              <video width="90%" controls>
                <source
                  src="assets/videos/cleanup-gardenbox.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>

          <section className="row">
            <div className="col-md-7">
              <h2>REPUESTO + SERVICIO CLEAN UP!</h2>
              <p>Repuesto Garden Box+ Servicio de Recambio</p>
              <ul>
                {this.state.products.map((item) => (
                  <li>
                    Repuesto {item.name} ${item.replacement_and_clean_up.format(0, 3, ',')}
                  </li>
                ))}
              </ul>
              <p>* Los precios NO incluyen envío.</p>
              <br />
              <div className="text-center">
                <a
                  className="btn btn-warning btn-lg"
                  role="button"
                  href="https://wa.me/573006291012"
                >
                  Solicita tu recambio
                </a>
              </div>
            </div>
            <div className="col-md-5">
              <br />
              <br />
              <br />
              <img width="90%" src="assets/gardenbox-servicio.jpg" />
            </div>
          </section>

          <br />
          <br />
          <br />
        </div>
      );
    } else {
      return (
        <div className="container section-howworks">
          <p>Cargando...</p>
        </div>
      );
    }
  }
}

export default HowWorks;
