import React from 'react';
//import renderHTML from 'react-render-html';

const SnapWidget = () => {
    return (
        <div className="snap-content">
            {
                <iframe src="https://snapwidget.com/embed/854500" class="snapwidget-widget" allowtransparency="true" frameborder="0" scrolling="no"></iframe>
            }
        </div>
    );
};

export default SnapWidget;
