import React, { Component } from 'react';
import Ship from './ship';
import { Link } from 'react-router-dom';

class Footer extends Component {

    constructor (props){
        super(props);
        this.state = {
            listShips: [
                {
                    key: '1',
                    icon: 'fas fa-truck',
                    title: 'Envío a tu hogar',
                    message: 'Llevamos tu Garden Box hasta la puerta de tu hogar'
                },
                {
                    key: '2',
                    icon: 'fas fa-phone',
                    title: 'Soporte',
                    message: 'Escríbenos al <a target="_blank" href="https://wa.me/573006291012">Whatsapp +57 3006291012</a> o por medio de nuestro chat en línea'
                },
                {
                    key: '3',
                    icon: 'fas fa-recycle',
                    title: 'EcoFiendly',
                    message: 'No más pads deschables, ni baños artificiales. <br />Grama 100% Natural'
                }
            ]
        }
    }
    render() {
        return (
            <div className="container-foooter">
                <section className="container">
                    <div className="row">
                    {
                        this.state.listShips.map( (item) =>
                            <Ship {...item} />
                        )
                    }
                    </div>
                </section>
                <section className="footer-bar">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <section className="copyright">
                                <div className="logo-footer">
                                    <Link to="/">
                                        <img width="140px" src="/assets/logo.png" />
                                    </Link>
                                    <p>&copy; Garden Box 2023 - Medellin, Colombia</p>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-6 col-xs-10">
                            <section className="footer-links">
                                <ul>
                                    <li>
                                        <a target="_blank" href="/assets/habeas-data.pdf">Habeas Data</a>
                                    </li>
                                </ul>
                            </section> 
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Footer;