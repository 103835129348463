import React, { Component, Fragment } from "react";
import { STEPS } from "../../data/constants";
import Step from "./components/step";
import FormBuyer from "./components/form-buyer";
import ActionBuy from "./components/action-buy";
import "../../utils";
import axios from "axios";
import Swal from 'sweetalert2';
import { handlePayment } from "../../utils/WebCheckout";
import { ProductSelection } from "./components/ProductSelection";
import { ExtraProductsSelection } from "./components/ExtraProductsSelection";
import { FormShipping } from "./components/FormShipping";
import { Coupon } from "./components/Coupon";

const ZONES_GROUP = {
  LOCAL_ZONES: 1,
  EXTERNAL_ZONES: 2,
};

class Checkout extends Component {
  constructor(props) {
    super(props);
    let serviceType = this.props.match.params.type;
    this.type = serviceType === "gardenbox" ? serviceType : "garden";
    this.description =
      serviceType === "gardenbox"
        ? "Compra de un Garden Box"
        : "Recambio de Grama";
    this.state = {
      showAlert: false,
      serviceType: this.type,
      serviceDescription: this.description,
      shipping: {},
      buyer: {},
      totalPriceProducts: 0,
      totalPriceExtraProducts: 0,
      gardeBoxType: "",
      unitPriceGarden: 0,
      quantity: 1,
      totalPriceFreight: 0,
      totalPaymentMethod: 0,
      paymentMethod: "",
      consignmentNumber: "",
      totalCheckout: 0, //totalPriceProducts + totalPriceFreight
      processing: false,
      allowOutsiteCity: true,
      products: [],
      localZones: [],
      externalZones: [],
      quantityExtraProducts: {},
      code: "",
      discount: 0
    };
  }

  async componentDidMount() {
    try {
      this.callProductsRequest();

      const localZonesResponse = await this.callZonesRequest(
        ZONES_GROUP.LOCAL_ZONES
      );
      this.setState({ localZones: localZonesResponse });

      const externalZonesResponse = await this.callZonesRequest(
        ZONES_GROUP.EXTERNAL_ZONES
      );
      this.setState({ externalZones: externalZonesResponse });
    } catch (error) {
      console.log("error componentDidMount", error);
    }
  }

  async callProductsRequest() {
    try {
      axios
        .get(`https://api.gardenboxcol.com/public/api/products`)
        .then((response) => {
          this.setState({ products: response.data.products });
        })
        .catch(function (error) {
          console.log("catch", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  async callZonesRequest(group) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://api.gardenboxcol.com/public/api/zones`, {
          params: {
            group,
          },
        })
        .then((response) => {
          resolve(response.data.zones);
        })
        .catch(function (error) {
          console.log("catch", error);
          reject(error);
        });
    });
  }

  async onChangeExtraProductsSelected(
    quantityExtraProducts,
    priceExtraProducts,
    totalPriceExtraProducts
  ) {
    await this.setState((prevState) => ({
      quantityExtraProducts,
      totalPriceExtraProducts,
      priceExtraProducts,
    }));
    this.updateTotalCheckout();
  }

  async onChangeProductSelected(
    gardeBoxType,
    unitPriceGarden,
    totalPriceProducts,
    quantity,
    allowOutsiteCity,
  ) {
    await this.setState((prevState) => ({
      gardeBoxType,
      unitPriceGarden,
      totalPriceProducts,
      quantity,
      allowOutsiteCity,
    }));
    this.updateTotalCheckout();
  }

  async onChangeFreight(shipping) {
    console.log('shipping', shipping)
    await this.setState((prevState) => ({
      totalPriceFreight: shipping.freight,
      shipping,
    }));
    this.updateTotalCheckout();
  }

  async onChangeBuyer(buyer) {
    await this.setState((prevState) => ({
      buyer,
    }));
  }

  async onChangePaymentMethod(
    totalPaymentMethod,
    paymentMethod,
    consignmentNumber
  ) {
    await this.setState((prevState) => ({
      totalPaymentMethod,
      paymentMethod,
      consignmentNumber,
    }));
    this.updateTotalCheckout();
  }

  updateTotalCheckout() {
    this.setState((prevState) => ({
      totalCheckout:
        prevState.totalPriceFreight +
        prevState.totalPriceProducts +
        prevState.totalPaymentMethod +
        prevState.totalPriceExtraProducts,
    }));
  }

  submitHandler(event) {
    event.preventDefault();
    this.changeProcessingState(true);
    const data = { ...this.state };
    data.products = null;
    data.localZones = null;
    data.externalZones = null;
    console.log('data', data)

    if (this.state.shipping.name === "") {
      this.setState({
        showAlert: true,
        processing: false,
      });
      this.showAlertError();
      return false;
    }
    let _this = this;
    axios
      .post(`https://api.gardenboxcol.com/public/api/order`, data)
      .then((response) => {
        if (this.state.paymentMethod === "wompy") {
          this.paymentSetup(response.data.reference);
        } else {
          this.props.history.push(
            `/order-received?ref=${response.data.reference}`
          ); //redirect
        }
        this.changeProcessingState(false);
      })
      .catch(function (error) {
        _this.changeProcessingState(false);
        console.log(error);
      });
  }

  changeProcessingState(processing) {
    this.setState({ processing });
  }

  paymentSetup(orderId) {
    handlePayment(orderId, this.state.totalCheckout);
  }

  onChangeCouponHandler(discount, code) {
    this.setState((prevState) => ({ discount, code }));
  }

  showAlertError() {
    Swal.fire({
      title: 'Datos incompletos',
      text: 'Por favor seleccione un lugar de envío',
      icon: 'error',
      confirmButtonText: 'Entiendo'
    });
  }

  render() {
    return (
      <div className="steps-container">
        {this.state.products.length > 0 && this.state.localZones.length > 0 ? (
          <section className="container">
            <br />
            <h1 className="text-center">
              {this.type === "gardenbox"
                ? "Compra un Garden Box"
                : "Recambio de Grama"}
            </h1>
            <h4 className="text-center">
              {this.type === "gardenbox"
                ? "Garden Box en Madera Inmunizada con grama"
                : ""}
            </h4>
            <div className="row">
              <form
                onSubmit={this.submitHandler.bind(this)}
                className="form-default"
              >
                <Step {...STEPS[0]} />
                <ProductSelection
                  onChangeComponent={this.onChangeProductSelected.bind(this)}
                  gardenTypes={this.state.products}
                  discount={this.state.discount}
                />
                {this.state.gardeBoxType !== "" && (
                  <Fragment>
                    <ExtraProductsSelection
                      gardenTypes={this.state.products}
                      gardenBoxTypeSelected={this.state.gardeBoxType}
                      maxQuantity={this.state.quantity}
                      onChangeComponent={this.onChangeExtraProductsSelected.bind(
                        this
                      )}
                    />
                    <Step {...STEPS[2]} />
                    <FormShipping
                      allowOutsiteCity={this.state.allowOutsiteCity}
                      gardenType={this.state.gardeBoxType}
                      onChangeComponent={this.onChangeFreight.bind(this)}
                      localZones={this.state.localZones}
                      externalZones={this.state.externalZones}
                    />
                    <Step {...STEPS[3]} />
                    <FormBuyer
                      onChangeComponent={this.onChangeBuyer.bind(this)}
                    />
                    <Step {...STEPS[4]} />
                    <Coupon onChangeComponent={this.onChangeCouponHandler.bind(this)} />
                    <ActionBuy
                      onChangeComponent={this.onChangePaymentMethod.bind(this)}
                      processing={this.state.processing}
                      totalCheckout={this.state.totalCheckout}
                      shippingMethod={this.state.shipping.deliveryOption}
                    />
                  </Fragment>
                )}
              </form>
            </div>
          </section>
        ) : (
          <div className="container">
            <p>cargando...</p>
          </div>
        )}
      </div>
    );
  }
}

export default Checkout;
