import React from 'react';

const Ship = props => (
    <div className="col-md-4">
        <div className="ship-container">
            <i className={ `${props.icon}` }></i>
            <div>
                <h4>{props.title}</h4>
                <p dangerouslySetInnerHTML={{__html: props.message}}></p>
            </div>
        </div>
    </div>
);

export default Ship;