import React, { Component } from "react";
import axios from "axios";

class OrderEnded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentStatus: 0,
      order: null,
    };
  }

  renderTitle(status) {
    switch (status) {
      case 1:
        return <h2>Orden procesada con éxito</h2>;
      case 2:
        return <h2>Pago rechazado</h2>;
      case 3:
        return <h2>Pago pendiente por procesar</h2>;
      case 4:
        return <h2>Error procesando tu pago</h2>;
    }
  }

  renderOrderContent(order) {
    return (
      <div className="table-order-summary">
        <h4>Detalle de tu pedido</h4>
        <h3>
          <strong>Orden: {order.id}</strong>
        </h3>
        <table className="table table-responsive">
          <tbody>
            <tr>
              <td>
                <strong>Garden</strong>
              </td>
              <td>{order.garden_type}</td>
              <td>
                <strong>Cantidad</strong>
              </td>
              <td>{order.quantity}</td>
            </tr>
            <tr>
              <td>
                <strong>Comprador</strong>
              </td>
              <td>{order.buyer_name}</td>
              <td>
                <strong>Correo electrónico</strong>
              </td>
              <td>{order.buyer_email}</td>
            </tr>
            <tr>
              <td>
                <strong>Teléfono</strong>
              </td>
              <td>{order.buyer_phone}</td>
              <td>
                <strong>Zona / Barrio / Método de envio</strong>
              </td>
              <td>{order.shipping_zone}</td>
            </tr>
            <tr>
              <td>
                <strong>Dirección</strong>
              </td>
              <td>{order.shopping_address || "No aplica"}</td>
              <td>
                <strong>Indicaciones</strong>
              </td>
              <td>{order.shipping_indications}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <h4>Totales</h4>
        <table className="table">
          <tbody>
            <tr>
              <td>
                <strong>Concepto</strong>
              </td>
              <td className="text-right">
                <strong>Cantidad</strong>
              </td>
              <td>
                <strong>Valor</strong>
              </td>
            </tr>
            <tr>
              <td>
                {order.garden_type}
                {order.percent_discount > 0 && (
                  <span className="text-success">
                    {" "}
                    (Descuento aplicado del {order.percent_discount}%)
                  </span>
                )}
              </td>
              <td className="text-right">{order.quantity}</td>
              <td>$ {order.amount_products.format(0, 3, ",")}</td>
            </tr>
            {order.quantity_customized > 0 && (
              <tr>
                <td>Personalización</td>
                <td className="text-right">{order.quantity_customized}</td>
                <td>
                  ${" "}
                  {(
                    order.unitprice_customized * order.quantity_customized
                  ).format(0, 3, ",")}
                </td>
              </tr>
            )}
            {order.quantity_safetymesh > 0 && (
              <tr>
                <td>Malla de Seguridad</td>
                <td className="text-right">{order.quantity_safetymesh}</td>
                <td>
                  ${" "}
                  {(
                    order.unitprice_safetymesh * order.quantity_safetymesh
                  ).format(0, 3, ",")}
                </td>
              </tr>
            )}
            {order.quantity_astralpet > 0 && (
              <tr>
                <td>Mon Ami Pet</td>
                <td className="text-right">{order.quantity_astralpet}</td>
                <td>
                  ${" "}
                  {(
                    order.unitprice_astralpet * order.quantity_astralpet
                  ).format(0, 3, ",")}
                </td>
              </tr>
            )}
            {order.quantity_biozym > 0 && (
              <tr>
                <td>Bio Zym</td>
                <td className="text-right">{order.quantity_biozym}</td>
                <td>
                  ${" "}
                  {(order.unitprice_biozym * order.quantity_biozym).format(
                    0,
                    3,
                    ","
                  )}
                </td>
              </tr>
            )}
            {order.quantity_bags > 0 && (
              <tr>
                <td>Bolsas</td>
                <td className="text-right">{order.quantity_bags}</td>
                <td>
                  ${" "}
                  {(order.unitprice_bags * order.quantity_bags).format(
                    0,
                    3,
                    ","
                  )}
                </td>
              </tr>
            )}
            {order.shipping_cost > 0 && (
              <tr>
                <td>Costo del envio </td>
                <td></td>
                <td>$ {order.shipping_cost.format(0, 3, ",")}</td>
              </tr>
            )}
            {order.amount_payment_method > 0 && (
              <tr>
                <td>Costo de la transacción</td>
                <td></td>
                <td>$ {order.amount_payment_method.format(0, 3, ",")}</td>
              </tr>
            )}
            <tr>
              <td></td>
              <td className="text-right">
                <h4>
                  <strong>Total</strong>
                </h4>
              </td>
              <td>
                <h4>
                  <strong>$ {order.total_checkout.format(0, 3, ",")}</strong>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderContent(status) {
    switch (status) {
      case 1:
        return (
          <p>
            Su orden fue recibida con éxito. Un miembro del equipo de{" "}
            <i>Garden Box</i> se comunicará contigo para coordinar los últimos
            detalles de la compra.
          </p>
        );
      case 2:
        return (
          <p>
            Su orden fue recibida pero el pago fue rechazado. <br />
            <br />
            Si lo desea, puede comunicarse con nuestro equipo de Soporte para
            ayudarle con otro método de pago.
          </p>
        );
      case 3:
        return (
          <p>
            Su orden fue recibida y se procederá en alistar su pedido
            <br />
            <br />
            Un miembro del equipo de <i>Garden Box</i> se comunicará contigo
            para coordinar los últimos detalles. Recuerda estar presente el día
            de la entrega para realizar el pago contra entrega.
          </p>
        );
      case 4:
        return <p>Su orden no fue procesada por un error con el pago.</p>;
    }
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const id = params.get("id");
    const reference = params.get("ref");
    if (id == null) {
      this.validateStatusPaymentResponse("PENDING");
      this.getOrderDetail(reference);
    } else {
      this.getStatusPaymentResponse(id);
    }
  }

  getOrderDetail(reference) {
    axios
      .get(`https://api.gardenboxcol.com/public/api/order-detail?reference=${reference}`)
      .then((response) => {
        this.setState({ order: response.data.order });
      })
      .catch((error) => {
        console.log("e", error);
        this.validateStatusPaymentResponse("404");
      });
  }

  getStatusPaymentResponse(id) {
    axios
      .post(`https://api.gardenboxcol.com/public/api/payment-validate`, { id_transaction: id })
      .then((response) => {
        this.validateStatusPaymentResponse(response.data.transaction_status);
        this.getOrderDetail(response.data.reference);
      })
      .catch((error) => {
        console.log("e", error);
        this.validateStatusPaymentResponse("404");
      });
  }

  validateStatusPaymentResponse(transactionStatus) {
    let statusPaymentResponse = 0;
    switch (transactionStatus) {
      case "APPROVED":
        statusPaymentResponse = 1;
        break;
      case "DECLINED":
        statusPaymentResponse = 2;
        break;
      case "VOIDED":
        statusPaymentResponse = 2;
        break;
      case "PENDING":
        statusPaymentResponse = 3;
        break;
      case "ERROR":
        statusPaymentResponse = 4;
        break;
      case "404":
        statusPaymentResponse = 4;
        break;
      default:
    }
    this.setState({ paymentStatus: statusPaymentResponse });
  }

  render() {
    return (
      <div className="success-order-container">
        <div className="container">
          {this.renderTitle(this.state.paymentStatus)}
          <hr />
          {this.renderContent(this.state.paymentStatus)}
          <br />
          {this.state.order && this.renderOrderContent(this.state.order)}
          <br />
          <br />
          <p>
            Si tiene alguna inquetud puedes llamar o escribir al{" "}
            <a target="_blank" href="https://wa.me/573006291012">
              Whatsapp +57 3006291012
            </a>{" "}
            y con gusto te ayudaremos.
          </p>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default OrderEnded;
