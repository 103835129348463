import React, { Component } from 'react';

class ProductExtra extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="jumbotron jumbotron-fluid extra-product">
                <div className="container extraproducts">
                    <h2>Adicionales</h2>
                    <br />
                    <section className="col-md-6">
                        <div className="image-extra"><img width="60%" src="assets/gardenboxes/malla.png" alt="gardenbox" /></div>
                        <h3>Malla de Seguridad</h3>
                        <p>Malla plástica ideal para cachorros o peludos que aman escarbar. Protege la grama de cualquier destrozo. Es fácil de instalar y limpiar.</p>
                    </section>
                    <section className="col-md-6">
                        <div className="image-extra"><img width="60%" src="assets/gardenboxes/personalizacion.png" alt="gardenbox" /></div>
                        <h3>Nombre Personalizado</h3>
                        <p>Puedes personalizar tu Garden Box con el nombre de tu mascota. Máximo 15 caracteres.</p>
                    </section>
                    <div className="clearrow"></div>
                    <section className="col-md-6">
                        <div className="image-extra"><img width="60%" src="assets/gardenboxes/biozym.png" alt="gardenbox" /></div>
                        <h3>Bio Zym</h3>
                        <p>El Bio Zym es un limpiador y desodorizante biodegradable a base de enzimas naturales que facilita que las bacterias presentes en el garden box, pisos o telas consuman cualquier material de desecho orgánico y al mismo tiempo elimina los olores.</p>
                    </section>
                    <section className="col-md-6">
                        <div className="image-extra"><img width="60%" src="assets/gardenboxes/monamipet.jpeg" alt="gardenbox" /></div>
                        <h3>Mon Ami Pet</h3>
                        <p>Ideal para limpiar pisos . Sus bioenzimas eliminan los malos olores especialmente los de materia orgánica, orina, materia fecal, vómito, etc.</p>
                    </section>
                    <div className="clearrow"></div>
                    <section className="col-md-6">
                        <div className="image-extra"><img width="60%" src="assets/gardenboxes/bolsas.png" alt="gardenbox" /></div>
                        <h3>Bolsas Saludables</h3>
                        <p>Bolsas Invisiblebag solubles en agua en minutos, biodegradables, compostables, no tóxicas,ambientalmente seguras y no deja residuos en la tierra. 0% plastico 100% ecoamigables.
                            <br />Las puedes desechar por el sanitario*
                            <br /><br />4 Rollitos de 10 bolsas ( 40 bolsas por paquete)
                            <br /><br />*Recuerda que no debe tener el nudo echo para su correcto procesamiento.</p>
                    </section>
                </div>
            </div>
        );
    }
}

export default ProductExtra;