import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import axios from 'axios';

const PAYMENT_METHOS = {
    'upon_delivery': "Contraentrega",
    'consignment': "Consignación",
    "wompy": "Wompy"
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            activePage: 1,
            totalItemsCount: 0
        };
    }

    paymentMethodStr (paymentMethod) {
        return PAYMENT_METHOS[paymentMethod];
    }

    componentDidMount() {
        this.getOrders(1);
    }

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
        this.getOrders(pageNumber);
    }

    async getOrders(pageNumber) {
        const token = await localStorage.getItem('token');
        let self = this;
        axios.get(`https://api.gardenboxcol.com/public/api/orders`, { params: {token: token, page: pageNumber} })
            .then(response => {
                const {data} = response
                this.setState({
                    list: data.list,
                    activePage: data.activePage,
                    itemsCountPerPage: data.itemsCountPerPage,
                    totalItemsCount: data.totalItemsCount
                });
            })
            .catch(function (error) {
                self.props.history.push('/');
            });
    }

    render() {
        return (
            <div className="container-admin">
                <br />
                <form method="post" action="/api/export">
                    <button type="submit" className="btn btn-primary">Exportar ordenes</button>
                </form>

                <br /><br />
                <table className="table table-responsive table-condensed table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Fecha</th>
                            <th scope="col">Garden</th>
                            <th scope="col">Código</th>
                            <th scope="col">Descuento</th>
                            <th scope="col">Cant.</th>
                            <th scope="col">Person.</th>
                            <th scope="col">Malla</th>
                            <th scope="col">Mon Ami Pet</th>
                            <th scope="col">Bio Zym</th>
                            <th scope="col">Bolsas</th>
                            <th scope="col">Barrio</th>
                            <th scope="col">Dirección</th>
                            <th scope="col">Indicaciones</th>
                            <th scope="col">Costo Envio</th>
                            <th scope="col">Comprador</th>
                            <th scope="col">Cedula</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Mascota</th>
                            <th scope="col">N. Mascota</th>
                            <th scope="col">Raza</th>
                            <th scope="col">Edad</th>
                            <th scope="col">M. Pago</th>
                            <th scope="col"># Consing.</th>
                            <th scope="col">Costo M.Pago</th>
                            <th scope="col">Costo Productos</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.list.map( (item, key) =>
                                <tr key={ key }>
                                    <td scope="row">{ item.id }</td>
                                    <td>{ item.created_at}</td>
                                    <td>{ item.garden_type}</td>
                                    <td>{ item.code_discount}</td>
                                    <td>{ item.percent_discount > 0 ? `${item.percent_discount}%`  : ''}</td>
                                    <td>{ item.quantity}</td>
                                    <td>{ item.quantity_customized ? item.quantity_customized: (item.has_customized == 0 ? '': 'Incluye')}</td>
                                    <td>{ item.quantity_safetymesh ? item.quantity_safetymesh: (item.has_safetymesh == 0 ? '': 'Incluye')}</td>
                                    <td>{ item.quantity_astralpet ? item.quantity_astralpet: (item.has_astralpet == 0 ? '': 'Incluye')}</td>
                                    <td>{ item.quantity_biozym ? item.quantity_biozym: (item.has_biozym == 0 ? '': 'Incluye')}</td>
                                    <td>{ item.quantity_bags ? item.quantity_bags: (item.has_bags == 0 ? '': 'Incluye')}</td>
                                    <td>{ item.shipping_zone}</td>
                                    <td>{ item.shopping_address}</td>
                                    <td>{ item.shipping_indications}</td>
                                    <td>{ item.shipping_cost}</td>
                                    <td>{ item.buyer_name}</td>
                                    <td>{ item.buyer_identification}</td>
                                    <td>{ item.buyer_email}</td>
                                    <td>{ item.buyer_phone}</td>
                                    <td>{ item.pet_type}</td>
                                    <td>{ item.pet_name}</td>
                                    <td>{ item.pet_breed}</td>
                                    <td>{ item.pet_age}</td>
                                    <td>{ this.paymentMethodStr(item.payment_method)}</td>
                                    <td>{ item.payment_consignment}</td>
                                    <td>{ item.amount_payment_method}</td>
                                    <td>{ item.amount_products}</td>
                                    <td>{ item.total_checkout}</td>
                                </tr>
                            )
                        }

                    </tbody>
                </table>
                <div>
                    <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange.bind(this)}
                    />
                </div>
                <br /><br />
            </div>
        );
    }
}

export default Admin;
