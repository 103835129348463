import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SecundarySlide extends Component {
    constructor(props){
        super(props);
        this.state = {
            backImage: 'assets/background-tapiz3.png',
            image: 'assets/garden-slide-2.png'
        };
    }

    render() {
        return (
            <div className="jumbotron jumbotron-fluid slide-app-secundary" style={{ backgroundColor: `#86aa39`}}>
                <div className="container">
                    <section className="col-md-5">
                        <h1 className="display-4 text-center">¿Cómo funciona?</h1>
                        <p className="lead text">Garden Box no solo te ofrece un producto.
                        Te entregamos un Jardín sanitario para tu mascota y brindamos el servicio de recambio mensual a
                        la puerta de tu casa!</p>
                        <p>¡Regálate la tranquilidad de saber que tu perro puede “ir” instintivamente sobre grama real!</p>
                        <div className="text-center">
                            <Link className="btn btn-warning btn-lg" role="button" to="/como-funciona">Ver más</Link>
                        </div>
                    </section>
                    <section className="col-md-7">
                        <img width="100%" src={`${this.state.image}`} alt="gardenbox" />
                    </section>
                </div>
            </div>
        );
    }
}

export default SecundarySlide;