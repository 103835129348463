import React, { Component } from "react";

const TYPES_PET = ["Perro", "Gato", "Minipig", "Conejo", "Cobaya", "Otro"];

class FormBuyer extends Component {
  constructor(props) {
    super(props);
    this.initState();
  }

  initState() {
    this.state = {
      name: "",
      identification: "",
      email: "",
      phone: "",
      petName: "",
      breed: "",
      age: "",
      petType: "",
    };
    this.emitEventComponent();
  }

  async handleChangeName(event) {
    await this.setState({ name: event.target.value });
    this.emitEventComponent();
  }

  async handleChangeIdentification(event) {
    await this.setState({ identification: event.target.value });
    this.emitEventComponent();
  }

  async handleChangeEmail(event) {
    await this.setState({ email: event.target.value });
    this.emitEventComponent();
  }

  async handleChangePhone(event) {
    await this.setState({ phone: event.target.value });
    this.emitEventComponent();
  }

  async handleChangePetName(event) {
    await this.setState({ petName: event.target.value });
    this.emitEventComponent();
  }

  async handleChangeBreed(event) {
    await this.setState({ breed: event.target.value });
    this.emitEventComponent();
  }

  async handleChangeAge(event) {
    await this.setState({ age: event.target.value });
    this.emitEventComponent();
  }

  async handleChangePetType(event) {
    await this.setState({ petType: event.target.value });
    this.emitEventComponent();
  }

  emitEventComponent() {
    this.props.onChangeComponent(this.state); //emit data
  }

  render() {
    return (
      <div className="col-md-6 col-md-offset-3 col-sm-12 form-containter">
        <div className="form-group">
          <label>
            <sup>*</sup>Nombre Completo
          </label>
          <input
            type="text"
            value={this.state.name}
            onChange={this.handleChangeName.bind(this)}
            name="name_buyer"
            className="form-control"
            id="name_buyer"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <sup>*</sup>Cedula
          </label>
          <input
            type="text"
            value={this.state.identification}
            onChange={this.handleChangeIdentification.bind(this)}
            name="identification_buyer"
            className="form-control"
            id="identification_buyer"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <sup>*</sup>Correo electrónico
          </label>
          <input
            type="email"
            value={this.state.email}
            onChange={this.handleChangeEmail.bind(this)}
            name="email_buyer"
            className="form-control"
            id="email_buyer"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <sup>*</sup>Teléfono de contacto celular
          </label>
          <input
            maxLength={10}
            type="text"
            value={this.state.phone}
            onChange={this.handleChangePhone.bind(this)}
            name="phone_buyer"
            className="form-control"
            id="phone_buyer"
            required
          />
        </div>
        <label>
          <sup>*</sup>¿Tu peludo es un?
        </label>
        <select
          required
          value={this.state.petType}
          onChange={this.handleChangePetType.bind(this)}
          id="pet_type"
          className="form-control"
        >
          {TYPES_PET.map((item, key) => (
            <option value={item} key={key}>
              {" "}
              {item}{" "}
            </option>
          ))}
        </select>
        <div className="form-group">
          <label>
            <sup>*</sup>Nombre de tu peludo
          </label>
          <input
            type="text"
            value={this.state.petName}
            onChange={this.handleChangePetName.bind(this)}
            name="pet_name_buyer"
            className="form-control"
            id="pet_name_buyer"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <sup>*</sup>Raza de tu peludo
          </label>
          <input
            type="text"
            value={this.state.breed}
            onChange={this.handleChangeBreed.bind(this)}
            name="breed_buyer"
            className="form-control"
            id="breed_buyer"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <sup>*</sup>Edad de tu peludo (en años)
          </label>
          <input
            type="text"
            value={this.state.age}
            onChange={this.handleChangeAge.bind(this)}
            name="age_pet_buyer"
            className="form-control"
            id="age_pet_buyer"
            required
          />
        </div>
      </div>
    );
  }
}

export default FormBuyer;
