import React, { Component } from 'react';
import MainSlide from './components/main-slide';
import SecundarySlide from './components/secundary-slide';
import Faq from './components/faq';
import AboutGarden from './components/about-garden';
import SnapWidget from './components/snap-widget';

class Home extends Component {
    render() {
        return (
            <div>
                <MainSlide />
                <SecundarySlide />
                <SnapWidget />
                <AboutGarden />
                <Faq />
            </div>
        );
    }
}

export default Home;