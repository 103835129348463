import React from 'react';

const WhatsappChat = () => {
    return (
        <div className="whatsapp-container">
            <a target="_blank" href="https://wa.me/573006291012">
                <img src="/assets/logo-whatsapp.png" width="80px" />
            </a>
        </div>
    );
};

export default WhatsappChat;