import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './Common/header';
import Footer from './Common/footer';

class Main extends Component {
    render() {
        return (
            <div className="container-app">
                <Header />
                <div>
                    {this.props.children}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Main;