import React, { useEffect, useState } from "react";

export const ProductSelection = ({
  gardenTypes = {},
  onChangeComponent,
  discount = 0,
}) => {
  const [name, setName] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [productSelected, setProductSelected] = useState({});

  const handleChangeQuantity = (event) => {
    const quantitySelected = event.target.value;
    setQuantity(quantitySelected);
  };

  const handleChangeProduct = (event) => {
    const valueSelected = event.target.value;
    if (valueSelected !== "") {
      const gardenType = gardenTypes.find((x) => x.name === valueSelected);
      setSuggestion(gardenType.suggestion);
      setName(gardenType.name);
      setProductSelected(gardenType);
    } else {
      setSuggestion("");
      setName("");
      setProductSelected({});
    }
  };

  const calculateCombo = () => {
    if (productSelected.price) {
      const discountApply = (quantity * productSelected.price * discount) / 100;
      const finalPrice = quantity * productSelected.price - discountApply;
      setTotal(finalPrice);
    } else {
      setTotal(0);
    }
  };

  /*const onChangeCouponHandler = (discount, code) => {
    setDiscount(discount);
    setCode(code);
  };*/

  useEffect(() => {
    calculateCombo();
  }, [name, quantity, discount]);

  useEffect(() => {
    onChangeComponent(
      name,
      productSelected.price || 0,
      total,
      quantity,
      productSelected.allowOutsiteCity || false
    );
  }, [total]);

  return (
    <div className="col-md-6 col-md-offset-3 col-sm-12 form-containter">
      <div className="form-group">
        <label>
          <sup>*</sup>SELECCIONA UN GARDEN BOX{" "}
          <small>Tamaños (Pequeño, Estándar, Grande, ExtraGrande)</small>
        </label>
        <select
          value={name}
          onChange={handleChangeProduct}
          id="garden_selection"
          className="form-control"
        >
          <option value="">---Selecciona una Garden Box---</option>
          {gardenTypes.map((item) => (
            <option value={item.name} key={item.key}>
              {" "}
              {item.name} - {item.size}{" "}
            </option>
          ))}
        </select>
      </div>
      <h5>
        <span className="badge badge-pill badge-primary">Recomendado</span>{" "}
        {suggestion}
      </h5>
      <div className="form-group">
        <div className="wrapper-select">
          <label>
            <sup>*</sup>Cantidad
          </label>
          <select
            value={quantity}
            onChange={handleChangeQuantity}
            id="garden_quatity_selection"
            className="form-control-select"
          >
            {[1, 2, 3, 4].map((item) => (
              <option key={item} value={item}>
                {" "}
                {item}{" "}
              </option>
            ))}
          </select>
        </div>
      </div>
      <h3 className="text-right">
        <small>
          ({quantity}) <i>{name}</i>
        </small>{" "}
        COP {total.format(0, 3, ",")}{" "}
      </h3>
    </div>
  );
};
