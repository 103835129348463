import React from 'react';

const FaqSimple = props => (
    <div className="item-faq">
        <h3>{ props.question }</h3>
        <p dangerouslySetInnerHTML={{__html: props.answer}}></p>
    </div>
);

export default FaqSimple;
