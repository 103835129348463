import React, { Fragment, useEffect, useState } from "react";
import {
  GARDEN_TYPES,
  DELIVERY_OPTIONS
} from "../../../data/constants";

const OTHER = "Otro";

const ANOTHER_ZONE = {
  id: 999999,
  name: OTHER,
  freight: 0,
  freight_big: 0,
};

export const FormShipping = ({
  allowOutsiteCity = false,
  gardenType,
  onChangeComponent,
  localZones,
  externalZones,
}) => {
  const [shippingData, setShippingData] = useState({});
  const [localZonesList, setLocalZonesList] = useState([
    ...localZones,
    ANOTHER_ZONE,
  ]);
  const [externalZonesList, setExternalZonesList] = useState([
    ...externalZones,
    ANOTHER_ZONE,
  ]);

  useEffect(() => {
    const state = {
      name: "",
      zone: null,
      freight: 0,
      address: "",
      instructions: "",
      gardenType: gardenType,
      otherCityChk: false,
      deliveryOption: "",
    };
    setShippingData(state);
  }, [gardenType]);

  const handleChangeZone = (event, list) => {
    const zone = list.find((item) => item.name === event.target.value);
    const name = zone ? zone.name : "";
    let freight;

    if (zone) {
      freight = zone.freight_big;
      if (
        shippingData.gardenType === GARDEN_TYPES[0].name
      ) {
        freight = zone.freight;
      }
    } else {
      freight = 0;
    }

    setShippingData({ ...shippingData, name, freight, zone });
  };

  const handleChangeAddress = (event) => {
    const address = event.target.value;
    setShippingData({ ...shippingData, address });
  };

  const handleChangeInstructions = (event) => {
    const instructions = event.target.value;
    setShippingData({ ...shippingData, instructions });
  };

  const handleDeliveryOptionChange = (e) => {
    const deliveryOption = e.target.value;
    const name =
      deliveryOption === DELIVERY_OPTIONS.DELIVERY_PICKUP_STORE
        ? "Recoger en tienda"
        : "";
    const freight = 0;
    setShippingData({ ...shippingData, name, freight, deliveryOption });
  };

  useEffect(() => {
    emitEventComponent();
  }, [shippingData]);

  const emitEventComponent = () => {
    onChangeComponent(
      shippingData
    );
  };

  const makeRadioDeliveryComponent = ({
    text,
    value,
    checked,
    warning = "",
    showWarning = false,
    disabled = false,
  }) => {
    return (
      <div className="radio custom-radio-input">
        <label>
          <input
            type="radio"
            value={value}
            name="deliveryRadio"
            onChange={handleDeliveryOptionChange}
            checked={checked}
            disabled={disabled}
          />
          {text} {showWarning && <strong>{warning}</strong>}
        </label>
      </div>
    );
  };

  return (
    <div className="col-md-6 col-md-offset-3 col-sm-12 form-containter">
      {makeRadioDeliveryComponent({
        text: "Envío al interior del área metropolitana y Oriente Antioqueño",
        value: DELIVERY_OPTIONS.DELIVERY_LOCAL,
        checked:
          shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_LOCAL,
      })}
      {makeRadioDeliveryComponent({
        text:
          "Envío a otra ciudad por fuera del área metropolitana de Medellín y Oriente Antioqueño",
        warning: "(Opción NO Disponible para este producto)",
        showWarning: !allowOutsiteCity,
        value: DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL,
        checked:
          shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL &&
          allowOutsiteCity,
        disabled: !allowOutsiteCity
      })}
      {makeRadioDeliveryComponent({
        text: "Recoger el producto en el área metropolitana de Medellín",
        value: DELIVERY_OPTIONS.DELIVERY_PICKUP_STORE,
        checked:
          shippingData.deliveryOption ===
          DELIVERY_OPTIONS.DELIVERY_PICKUP_STORE,
      })}
      <br />

      {shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL && allowOutsiteCity ?
        (<div>
          <label>
            <sup>*</sup>SELECCIONA UNA ZONA{" "}
            <small>Envíos a otras ciudades del país</small>
          </label>
          <select
            required
            value={shippingData.name}
            onChange={(e) => handleChangeZone(e, [...externalZonesList])}
            id="zone_country"
            className="form-control"
          >
            <option>Seleccione el sector de envío</option>
            {externalZonesList.map((item, key) => (
              <option value={item.name} key={key}>
                {" "}
                {item.name}{" "}
              </option>
            ))}
          </select>
        </div>
      ): <Fragment />}

      {shippingData.deliveryOption ===
        DELIVERY_OPTIONS.DELIVERY_PICKUP_STORE && (
        <div>
          <div className="form-group">
            <label>
              <sup>*</sup>Déjanos tu dirección
            </label>
            <input
              type="text"
              value={shippingData.address}
              onChange={handleChangeAddress}
              name="address"
              className="form-control"
              id="address"
              required
            />
          </div>
          <br /><br />
          <p className="highligh-text">
            Nos comunicaremos contigo para darte las indicaciones de recogida de
            tu Garden Box
          </p>
          <div className="center-image">
            <a
              target="_blank"
              href="https://www.google.com/maps/place/Garden+Box/@6.1911457,-75.5586257,17z/data=!3m1!4b1!4m5!3m4!1s0x8e4682fbd71c5655:0x807d5598cd1d7636!8m2!3d6.1911457!4d-75.556437?shorturl=1"
            >
              <img src="/assets/mapa-gardenbox.png" width="280" />
            </a>
            <p>Calle 5 sur #13-190 Medellín</p>
          </div>
        </div>
      )}

      {shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_LOCAL && (
        <div>
          <label>
            <sup>*</sup>SELECCIONA UNA ZONA{" "}
            <small>
              Envíos a toda el área metropolitana y Oriente Antioqueño
            </small>
          </label>
          <select
            required
            value={shippingData.name}
            onChange={(e) => handleChangeZone(e, [...localZonesList])}
            id="zone_country"
            className="form-control"
          >
            <option>Seleccione el sector de envío</option>
            {localZonesList.map((item, key) => (
              <option value={item.name} key={key}>
                {" "}
                {item.name}{" "}
              </option>
            ))}
          </select>
        </div>
      )}

      {shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_LOCAL ||
      (shippingData.deliveryOption === DELIVERY_OPTIONS.DELIVERY_NOT_LOCAL && allowOutsiteCity ) ? (
        <section>
          <br />
          {shippingData.name === OTHER && (
            <div>
              <p className="highligh-text">
                Nos pondremos en contacto contigo para acordar el valor del
                envío.
              </p>
            </div>
          )}
          <div className="form-group">
            <label>
              <sup>*</sup>DIRECCIÓN DE ENVÍO
            </label>
            <input
              type="text"
              value={shippingData.address}
              onChange={handleChangeAddress}
              name="address"
              className="form-control"
              id="address"
              required
            />
          </div>
          <div className="form-group">
            <label>
              INDICACIONES{" "}
              <small>
                Barrio, Sector, Conjunto residencial, Apartamento, etc
              </small>
            </label>
            <textarea
              value={shippingData.instructions}
              onChange={handleChangeInstructions}
              rows="2"
              name="address_description"
              className="form-control"
              id="address_description"
            />
          </div>
          <br />
          <h3 className="text-right">
            <small>Costo del envío</small> COP{" "}
            {shippingData.freight.format(0, 3, ",")}
          </h3>
        </section>
      ) : (
        <Fragment />
      )}
    </div>
  );
};
